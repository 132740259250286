import React, { useEffect, useState } from "react";
import "./styles.scss";
import LayoutDefault from "../../components/Wrappers/LayoutDefault";
import { galleryArray } from "../../mocks/imagesMock";
import ImageAndText from "../../components/ImageAndText";
import YouTubeFrame from "../../components/YouTubeFrame";
import { jadeOceanInfo } from "../../mocks/projectInfo";
import Hr from "../../components/Hr";

//ICON
import locationIcon from "../../assets/icons/location-icon.svg";

//IMAGES
import sunnyIsles from "../../assets/images/jade-ocean/sunny-isles-beach-4004.jpg";
import jadeOcean1 from "../../assets/images/jade-ocean/jade-ocean_4004/055A1809.jpg";
import jadeOcean2 from "../../assets/images/jade-ocean/jade-ocean_4004/055A1775.jpg";
import jadeOcean3 from "../../assets/images/jade-ocean/jade-ocean_4004/055A1688.jpg";
import jadeOcean4 from "../../assets/images/jade-ocean/jade-ocean_4004/055A1617.jpg";

import jadeOcean5 from "../../assets/images/jade-ocean/jade-ocean_4004/055A1559.jpg";
import jadeOcean6 from "../../assets/images/jade-ocean/jade-ocean_4004/055A1547.jpg";
import jadeOcean7 from "../../assets/images/jade-ocean/jade-ocean_4004/055A1630.jpg";

import jadeOcean8 from "../../assets/images/jade-ocean/jade-ocean_4004/055A1565.jpg";

//IMAGES AMENITIES
import jadeOceanAmenities02 from "../../assets/images/jade-ocean/jade-ocean_4004/jade-ocean_amenities02.jpg";
import jadeOceanAmenities03 from "../../assets/images/jade-ocean/jade-ocean_4004/jade-ocean_amenities03.jpg";
import jadeOceanAmenities04 from "../../assets/images/jade-ocean/jade-ocean_4004/jade-ocean_amenities04.jpg";
import jadeOceanAmenities05 from "../../assets/images/jade-ocean/jade-ocean_4004/jade-ocean_amenities05.jpg";
import jadeOceanAmenities06 from "../../assets/images/jade-ocean/jade-ocean_4004/jade-ocean_amenities06.jpg";
import jadeOceanAmenities07 from "../../assets/images/jade-ocean/jade-ocean_4004/jade-ocean_amenities07.jpg";
import jadeOceanAmenities08 from "../../assets/images/jade-ocean/jade-ocean_4004/jade-ocean_amenities08.jpg";
import jadeOceanAmenities09 from "../../assets/images/jade-ocean/jade-ocean_4004/jade-ocean_amenities09.jpg";
import jadeOceanAmenities10 from "../../assets/images/jade-ocean/jade-ocean_4004/jade-ocean_amenities10.jpg";
import jadeOceanAmenities11 from "../../assets/images/jade-ocean/jade-ocean_4004/jade-ocean_amenities11.jpg";
import jadeOceanAmenities12 from "../../assets/images/jade-ocean/jade-ocean_4004/jade-ocean_amenities12.jpg";
import jadeOceanAmenities13 from "../../assets/images/jade-ocean/jade-ocean_4004/jade-ocean_amenities13.jpg";

//IMAGES BUILDING
import jadeOceanBuilding01 from "../../assets/images/jade-ocean/jade-ocean_4004/jade-ocean_building01.jpg";
import jadeOceanBuilding02 from "../../assets/images/jade-ocean/jade-ocean_4004/jade-ocean_building02.jpg";
import jadeOceanBuilding03 from "../../assets/images/jade-ocean/jade-ocean_4004/jade-ocean_building03.jpg";
import jadeOceanBuilding04 from "../../assets/images/jade-ocean/jade-ocean_4004/jade-ocean_building04.jpg";
import jadeOceanBuilding05 from "../../assets/images/jade-ocean/jade-ocean_4004/jade-ocean_building05.jpg";
import jadeOceanBuilding06 from "../../assets/images/jade-ocean/jade-ocean_4004/jade-ocean_building06.jpg";

import Button from "../../components/Buttons/Button";
import HomeListDetails from "../../components/HomeListDetails";

const LandingPage: React.FC = () => {
  const [wideScreen, setWideScreen] = useState(window.innerWidth < 765);

  useEffect(() => {
    const handleResize = () => {
      setWideScreen(window.innerWidth < 765);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <LayoutDefault
      hasImageInFooter
      slidesCarousel={[
        { id: "1", backgroundImage: jadeOceanBuilding04 },
        { id: "2", backgroundImage: jadeOceanBuilding05 },
        { id: "3", backgroundImage: jadeOceanBuilding06 },
        { id: "4", backgroundImage: jadeOceanBuilding03 },
      ]}
      className="landing-page"
    >
      <div className="main-info">
        <h3>Discover Jade Ocean, Unit 4004</h3>
        <p>
          Located at the prestigious address of 17121 Collins Avenue in Sunny
          Isles Beach, Florida, Jade Ocean represents the zenith of modern
          luxury and architectural innovation. Unit 4004, an extraordinary
          residence within this iconic building, offers an unparalleled living
          experience where sophistication, comfort, and breathtaking ocean views
          come together to create a unique lifestyle.
        </p>
      </div>

      <YouTubeFrame videoURL={"https://www.youtube.com/watch?v=93FdyYtpSeg"} />

      <HomeListDetails
        details={jadeOceanInfo.principal}
        specifications={jadeOceanInfo.specifications}
      />

      <Hr />
      <ImageAndText
        className="margin-vert-40"
        images={[{ id: "1", img: jadeOcean1 }]}
        position="vert-photo-text"
        gallery
        textTitle="An Exquisite Oceanfront Residence"
        textParagraph={`Unit 4004 at Jade Ocean is a meticulously crafted 
					3-bedroom, 3.5-bathroom condominium that spans approximately 2,000 
					square feet of living space. Every detail of this residence has been 
					designed to maximize comfort and style while showcasing the stunning 
					views of the Atlantic Ocean.`}
      />

      <ImageAndText
        className="margin-vert-40"
        images={[{ id: "1", img: jadeOcean2 }]}
        position="hor-text-photo"
        textTitle="Breathtaking Living Spaces with Panoramic Ocean Views"
        textParagraph={`Step into Unit 4004 and be instantly captivated by the 
					expansive living area, where floor-to-ceiling windows flood the space 
					with natural light, offering breathtaking, uninterrupted ocean views. 
					The open floor plan seamlessly connects the living room, dining area, 
					and kitchen, creating an ideal setting for both relaxation and 
					entertaining. With modern, high-end finishes and a neutral palette 
					that highlights the vibrant blue of the ocean beyond, this stylish 
					yet cozy living room is perfect for hosting guests or enjoying 
					peaceful family moments.`}
      />
      <ImageAndText
        className="margin-vert-40"
        images={[{ id: "1", img: jadeOcean3 }]}
        position="hor-photo-text"
        textTitle="A Chef’s Dream: The Gourmet Kitchen"
        textParagraph={`The gourmet kitchen in Unit 4004 is a culinary 
					masterpiece, designed to impress even the most discerning chefs. 
					Outfitted with top-of-the-line appliances, including a Sub-Zero 
					refrigerator and Miele cooktop, oven, and dishwasher, it offers both 
					style and functionality. Custom Italian cabinetry provides ample 
					storage, while sleek countertops offer plenty of space for meal 
					preparation. At the heart of the kitchen, a large island doubles as a 
					social hub, ideal for casual dining or savoring a glass of wine while 
					soaking in the stunning ocean views.`}
      />
      <ImageAndText
        className="margin-vert-40"
        images={[{ id: "1", img: jadeOcean4 }]}
        position="hor-text-photo"
        textTitle="Luxurious Bedrooms Designed for Comfort and Style"
        textParagraph={`Unit 4004 features three spacious bedrooms, each 
					designed as a serene private retreat. The master suite exudes luxury, 
					with floor-to-ceiling windows that showcase breathtaking ocean views, 
					while its generous layout accommodates a king-size bed, seating area, 
					and additional furnishings without compromising its airy ambiance. The 
					en-suite master bathroom feels like a private spa, complete with a 
					deep soaking tub, rain shower, and dual vanities, all accented by 
					high-end marble and designer fixtures. The additional bedrooms are 
					equally impressive, each with an en-suite bathroom, ample closet 
					space, and large windows that flood the rooms with natural light, 
					making them perfect as guest suites, home offices, or fitness areas.`}
      />

      <ImageAndText
        className="margin-vert-40"
        images={[
          { id: "1", img: jadeOcean5 },
          { id: "2", img: jadeOcean6 },
          { id: "3", img: jadeOcean7 },
        ]}
        position="vert-text-photo"
        gallery
        textTitle="Expansive Balcony with Unmatched Oceanfront Views"
        textParagraph={`A standout feature of Unit 4004 is its expansive private 
					balcony, which spans the entire width of the unit and offers direct 
					access from both the living room and master bedroom. This outdoor 
					oasis provides the perfect setting to enjoy the fresh ocean breeze 
					and panoramic views of the Atlantic. Whether you're sipping your 
					morning coffee, enjoying evening cocktails, or simply unwinding to the 
					sound of the waves, the balcony serves as a tranquil retreat for 
					relaxation and outdoor living.`}
      />

      <Hr />

      <ImageAndText
        className="margin-vert-40"
        images={galleryArray}
        gallery
        position="vert-text-photo"
        textTitle="Explore the Beauty of Jade Ocean"
        textParagraph={`Immerse yourself in the stunning visuals of Jade Ocean, where 
					modern design meets breathtaking ocean views. Our gallery showcases 
					the building's striking architecture, luxurious interiors, and the 
					serene lifestyle that awaits. From panoramic vistas to meticulously 
					designed spaces, each image reflects the elegance and sophistication 
					that define the Jade Ocean experience.`}
      />

      <Hr />

      <ImageAndText
        className="margin-vert-40"
        images={[{ id: "1", img: jadeOcean8 }]}
        position="vert-text-photo"
        gallery
        textTitle="Unmatched Amenities at Jade Ocean"
        textParagraph={`Jade Ocean offers an unparalleled lifestyle with its world-class amenities. Designed by renowned architect Carlos Ott, this iconic building features a grand lobby with designer furnishings and 24-hour concierge service, ensuring residents feel welcomed and cared for. Two infinity-edge pools provide serene relaxation—enjoy invigorating morning swims in the sunrise pool or unwind with stunning sunset views from the west side pool.`}
      />

      <ImageAndText
        className="margin-vert-40"
        images={[
          { id: "1", img: jadeOceanAmenities02 },
          { id: "2", img: jadeOceanAmenities04 },
          { id: "3", img: jadeOceanAmenities03 },
          { id: "4", img: jadeOceanAmenities05 },
        ]}
        position="hor-photo-text"
        textTitle="State-of-the-Art Fitness and Spa"
        textParagraph={`For fitness enthusiasts, the state-of-the-art fitness center is equipped with the latest machines, free weights, and a dedicated yoga and aerobics studio for group classes or private sessions. After a workout, indulge in rejuvenating treatments at the luxurious on-site spa, complete with a sauna and steam room for the ultimate relaxation experience.`}
      />

      <ImageAndText
        className="margin-vert-40"
        images={[
          { id: "1", img: jadeOceanAmenities06 },
          { id: "2", img: jadeOceanAmenities07 },
          { id: "3", img: jadeOceanAmenities08 },
          { id: "4", img: jadeOceanAmenities09 },
        ]}
        position="hor-text-photo"
        textTitle="Family-Friendly Spaces and Entertainment"
        textParagraph={`Families can enjoy the children’s playroom, a safe space for young residents, while the media room offers a cozy setting for movie nights and gatherings. For professionals, the fully equipped business center and conference room provide the perfect work environment.`}
      />

      <ImageAndText
        className="margin-vert-40"
        images={[
          { id: "1", img: jadeOceanAmenities10 },
          { id: "2", img: jadeOceanAmenities11 },
          { id: "3", img: jadeOceanAmenities12 },
          { id: "4", img: jadeOceanAmenities13 },
        ]}
        position="hor-photo-text"
        textTitle="Exclusive Beach Access and 24-Hour Services"
        textParagraph={`Residents enjoy direct access to Sunny Isles Beach with exclusive beach services, including chairs, umbrellas, and towels. Additionally, 24-hour valet parking and concierge services ensure a seamless and convenient living experience at Jade Ocean.`}
      />

      <Hr />
      <ImageAndText
        className="margin-vert-40"
        images={[{ id: "1", img: sunnyIsles }]}
        position="hor-text-photo"
        textTitle="The Location: Sunny Isles Beach"
        textParagraph={`Sunny Isles Beach stands as one of South Florida's most 
					sought-after neighborhoods, celebrated for its pristine beaches, 
					upscale shopping, and gourmet dining experiences. Located on the 
					iconic Collins Avenue, Jade Ocean places residents just moments from 
					the vibrant offerings of the area, including world-class shopping at 
					Aventura Mall, fine dining, and endless entertainment options. The 
					neighborhood is also home to top-rated schools, making it a perfect 
					setting for families, while its close proximity to major highways and 
					airports ensures effortless travel to Miami’s hotspots and beyond.`}
        linkTo={{
          linkHref:
            "https://www.google.com/maps/place/Jade+Ocean/@25.9343413,-80.1235918,953m/data=!3m2!1e3!4b1!4m6!3m5!1s0x88d9ad1f50769b9d:0xda8d7863a8094d74!8m2!3d25.9343413!4d-80.1210169!16s%2Fm%2F0wfckbv?entry=ttu&g_ep=EgoyMDI0MDkzMC4wIKXMDSoASAFQAw%3D%3D",
          linkText: "17121 COLLINS AVE | SUNNY ISLES BEACH, FL",
        }}
      />
    </LayoutDefault>
  );
};

export default LandingPage;
