import React, { useState } from "react";
import "./styles.scss";

import ThiagoProfile from "../../assets/images/tc-miami_profile-photo_thiago-costa-01.jpg";
import Hr from "../Hr";
import TextArea from "../TextArea";
import Button from "../Buttons/Button";
import InputText from "../InputText";
import SimpleSelect from "../Selects/SimpleSelect";
import { setupPhoneNumberInput } from "../../utils/masks";
import { CountryCode } from "libphonenumber-js";
import codesMock from "../../mocks/countryCodes.json";
import checkCircleGreen from "../../assets/icons/check-circle-green.svg";
import { IContact } from "../../types/IContact";
import SendEmailContact from "../../api/sendEmailContact";

interface BusinessCardProps {
  className?: string;
  hasImageInFooter?: boolean;
}

const BusinessCard: React.FC<BusinessCardProps> = (props) => {
  const { className, hasImageInFooter } = props;
  const [userName, setUsername] = useState("");
  const [userNameWarning, setUsernameWarning] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [userEmailWarning, setUserEmailWarning] = useState(false);
  const [message, setMessage] = useState("");
  const [messageWarning, setMessageWarning] = useState(false);
  const [userPhone, setUserPhone] = useState("");
  const [phoneCode, setPhoneCode] = useState<string>("");
  const [countryCode, setCountryCode] = useState("" as string);
  const [userPhoneWarning, setUserPhoneWarning] = useState(false);
  const [isSuccessFormSubmit, setIsSuccessFormSubmit] = useState(false);

  const countryCodeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPhoneCode(e.target.value);
  };

  const inputPhoneHandler = (e: string) => {
    if (phoneCode) {
      let codeCountry = JSON.parse(phoneCode);

      const formattedNumber = setupPhoneNumberInput(
        codeCountry.code as CountryCode,
        e
      );

      setUserPhone(formattedNumber);

      setCountryCode(codeCountry.countryCode);
    } else {
      setUserPhone(e);
      setUserPhone(e.replace(/\D/g, ""));
    }
  };

  const formSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (userName.length < 3) {
      setUsernameWarning(true);
      setTimeout(() => setUsernameWarning(false), 3000);
      return;
    }

    if (!userEmail || !userEmail.includes("@") || !userEmail.includes(".com")) {
      setUserEmailWarning(true);
      setTimeout(() => setUserEmailWarning(false), 3000);
      return;
    }

    const prepareData: IContact = {
      clientEmail: userEmail,
      clientMessage: message === "" ? " - " : message,
      clientName: userName,
      clientPhone: userPhone === "" ? " - " : userPhone,
    };

    const response = await SendEmailContact(prepareData);

    setTimeout(() => {
      setIsSuccessFormSubmit(false);
    }, 4000);

    setIsSuccessFormSubmit(true);
    setUserEmail("");
    setUserPhone("");
    setUsername("");
    setMessage("");
    setPhoneCode("");
  };
	
  return (
    <div className="contact__section padding-hor-24">
      <h3 id="contact-us" className="no-margin">
        Contact Us
      </h3>
      <p>
        Ready to explore this stunning residence at Jade Ocean? Reach out today
        to schedule a private tour of Unit 4004, where breathtaking ocean views,
        world-class amenities, and sophisticated design await. Don't miss the
        opportunity to experience the best in oceanfront living!
      </p>
      <div id="business-card" className={`${className ?? ""} `}>
        {hasImageInFooter && <img src={ThiagoProfile} alt="Thiago Costa" />}

        <section className="contact-info-container">
          {hasImageInFooter ? (
            <>
              <h2 className="no-margin">Thiago Costa</h2>
              <h4 className="no-margin">Top Producer</h4> <Hr />
            </>
          ) : (
            <h3>Contact Thiago</h3>
          )}

          <table>
            <tbody className="contact-info-container__table-tbody">
              <tr>
                <td className="table-key">Phone:</td>
                <td className="table-value">
                  <a
                    href="https://wa.me/13053704780"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    +1 (305) 370-4780
                  </a>
                </td>
              </tr>
              <tr>
                <td className="table-key">Email:</td>
                <td className="table-value">
                  <a className="email-link" href="mailto:tcosta@fir.com">
                    tcosta@fir.com
                  </a>
                </td>
              </tr>
              <tr>
                <td className="table-key">Office:</td>
                <td className="table-value">
                  Fortune Christie's International
                  <br />
                  1390 Brickell Avenue, Suite 104
                  <br />
                  Miami, FL 33131
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        {!isSuccessFormSubmit ? (
          <form className="contact__form">
            <InputText
              value={userName}
              placeholder="Name"
              type="text"
              onChangeInput={setUsername}
              isWarning={userNameWarning}
            />
            <InputText
              placeholder="E-mail"
              value={userEmail}
              type="email"
              onChangeInput={setUserEmail}
              isWarning={userEmailWarning}
            />
            <div className="grid-column-phone">
              <SimpleSelect
                options={codesMock.map((code) => {
                  return {
                    label: `${code.flag} ${code.code} ${code.countryCode}`,
                    value: JSON.stringify(code),
                  };
                })}
                onChange={countryCodeHandler}
                value={phoneCode}
                defaultValueText={"Select"}
              />
              <InputText
                value={userPhone}
                onChangeInput={inputPhoneHandler}
                placeholder="Phone number"
                isWarning={userPhoneWarning}
              />
            </div>
            <TextArea
              placeholder="Message"
              value={message}
              onChangeInput={setMessage}
              isWarning={messageWarning}
            />

            <Button buttonStyle="primary" onClick={formSubmit}>
              Submit
            </Button>
          </form>
        ) : (
          <section className="success-send-form">
            <img
              src={checkCircleGreen}
              alt="circle check green"
              className="success-send-form__icon"
            />
            <h3 className="success-send-form__title no-margin">
              Message Sent!
            </h3>
            <p className="success-send-form__details">
              We will contact you shortly!{" "}
            </p>
          </section>
        )}
      </div>
    </div>
  );
};

export default BusinessCard;
