export const jadeOceanInfo = {
  principal: [
    {
      id: 0,
      name: "Bedrooms",
      quantity: "3",
    },
    {
      id: 1,
      name: "Bathrooms",
      quantity: "3",
    },
    {
      id: 2,
      name: "Half Bath",
      quantity: "1",
    },
    {
      id: 3,
      name: "SqFt",
      quantity: "1,933",
    },
    {
      id: 5,
      name: "Built",
      quantity: "2009",
    },
  ],

  specifications: [
    { id: 0, key: "Living Area", value: "1,933 sqft" },
    { id: 1, key: "Bedrooms", value: "3" },
    { id: 2, key: "Bathrooms", value: "3" },
    { id: 3, key: "Half Bathroom", value: "1" },
    { id: 4, key: "Year Built", value: "2009" },
    { id: 5, key: "Garage Spaces", value: "2" },
    { id: 6, key: "Waterfront Property", value: "Yes" },
    { id: 7, key: "Community", value: "Jade Ocean" },
    { id: 8, key: "Association Fee", value: "$2,872" },
    { id: 9, key: "Property Tax (2023)", value: "$33,158.14" },
  ],
};
