import ora01 from "../assets/images/ora/ora01.jpg";
import ora02 from "../assets/images/ora/ora02.jpg";
import ora03 from "../assets/images/ora/ora03.jpg";
import ora04 from "../assets/images/ora/ora04.jpg";
import ora05 from "../assets/images/ora/ora05.jpg";
import ora06 from "../assets/images/ora/ora06.jpg";
import ora07 from "../assets/images/ora/ora07.jpg";
import collins01 from "../assets/images/collinsave/colins01.jpeg";
import collins02 from "../assets/images/collinsave/colins02.jpeg";
import collins03 from "../assets/images/collinsave/colins03.jpeg";
import collins04 from "../assets/images/collinsave/colins04.jpeg";
import collins05 from "../assets/images/collinsave/colins05.jpeg";
import tower01 from "../assets/images/tower/tower01.jpeg";
import tower02 from "../assets/images/tower/tower02.jpeg";
import tower03 from "../assets/images/tower/tower03.jpeg";
import tower04 from "../assets/images/tower/tower04.jpeg";
import tower05 from "../assets/images/tower/tower05.jpeg";
import signature01 from "../assets/images/signature/signature01.jpg";
import signature02 from "../assets/images/signature/signature02.jpg";
import signature03 from "../assets/images/signature/signature03.jpg";
import signature04 from "../assets/images/signature/signature04.jpg";
import signature05 from "../assets/images/signature/signature05.jpg";
import signature06 from "../assets/images/signature/signature06.jpg";
import signature07 from "../assets/images/signature/signature07.jpg";
import signature08 from "../assets/images/signature/signature08.jpg";
import signature09 from "../assets/images/signature/signature09.jpg";
import discover01 from "../assets/images/discover/list01.png";
import discover03 from "../assets/images/discover/list03.png";
import banner01 from "../assets/images/banner/banner01.jpg";
import House from "../assets/images/image.png";
import Test1 from "../assets/images/banner-test.jpg";
import jadeOceanGallery01 from "../assets/images/jade-ocean/jade-ocean_4004/055A1521.jpg";
import jadeOceanGallery02 from "../assets/images/jade-ocean/jade-ocean_4004/055A1522.jpg";
import jadeOceanGallery03 from "../assets/images/jade-ocean/jade-ocean_4004/055A1534.jpg";
import jadeOceanGallery04 from "../assets/images/jade-ocean/jade-ocean_4004/055A1543.jpg";
import jadeOceanGallery05 from "../assets/images/jade-ocean/jade-ocean_4004/055A1547.jpg";
import jadeOceanGallery06 from "../assets/images/jade-ocean/jade-ocean_4004/055A1560.jpg";
import jadeOceanGallery07 from "../assets/images/jade-ocean/jade-ocean_4004/055A1572.jpg";
import jadeOceanGallery08 from "../assets/images/jade-ocean/jade-ocean_4004/055A1584.jpg";
import jadeOceanGallery09 from "../assets/images/jade-ocean/jade-ocean_4004/055A1590.jpg";
import jadeOceanGallery10 from "../assets/images/jade-ocean/jade-ocean_4004/055A1598.jpg";
import jadeOceanGallery11 from "../assets/images/jade-ocean/jade-ocean_4004/055A1607-HDR.jpg";
import jadeOceanGallery12 from "../assets/images/jade-ocean/jade-ocean_4004/055A1617.jpg";
import jadeOceanGallery13 from "../assets/images/jade-ocean/jade-ocean_4004/055A1620.jpg";
import jadeOceanGallery14 from "../assets/images/jade-ocean/jade-ocean_4004/055A1626.jpg";
import jadeOceanGallery15 from "../assets/images/jade-ocean/jade-ocean_4004/055A1628.jpg";
import jadeOceanGallery16 from "../assets/images/jade-ocean/jade-ocean_4004/055A1638.jpg";
import jadeOceanGallery17 from "../assets/images/jade-ocean/jade-ocean_4004/055A1642.jpg";
import jadeOceanGallery18 from "../assets/images/jade-ocean/jade-ocean_4004/055A1652-HDR.jpg";
import jadeOceanGallery19 from "../assets/images/jade-ocean/jade-ocean_4004/055A1658.jpg";
import jadeOceanGallery20 from "../assets/images/jade-ocean/jade-ocean_4004/055A1675.jpg";
import jadeOceanGallery21 from "../assets/images/jade-ocean/jade-ocean_4004/055A1685.jpg";
import jadeOceanGallery22 from "../assets/images/jade-ocean/jade-ocean_4004/055A1688.jpg";
import jadeOceanGallery23 from "../assets/images/jade-ocean/jade-ocean_4004/055A1693.jpg";
import jadeOceanGallery24 from "../assets/images/jade-ocean/jade-ocean_4004/055A1695.jpg";
import jadeOceanGallery25 from "../assets/images/jade-ocean/jade-ocean_4004/055A1702.jpg";
import jadeOceanGallery26 from "../assets/images/jade-ocean/jade-ocean_4004/055A1704.jpg";
import jadeOceanGallery27 from "../assets/images/jade-ocean/jade-ocean_4004/055A1714.jpg";
import jadeOceanGallery28 from "../assets/images/jade-ocean/jade-ocean_4004/055A1708.jpg";
import jadeOceanGallery29 from "../assets/images/jade-ocean/jade-ocean_4004/055A1715.jpg";
import jadeOceanGallery30 from "../assets/images/jade-ocean/jade-ocean_4004/055A1719-HDR-Editar.jpg";
import jadeOceanGallery31 from "../assets/images/jade-ocean/jade-ocean_4004/055A1742.jpg";
import jadeOceanGallery32 from "../assets/images/jade-ocean/jade-ocean_4004/055A1764.jpg";
import jadeOceanGallery33 from "../assets/images/jade-ocean/jade-ocean_4004/055A1767.jpg";
import jadeOceanGallery34 from "../assets/images/jade-ocean/jade-ocean_4004/055A1775.jpg";
import jadeOceanGallery35 from "../assets/images/jade-ocean/jade-ocean_4004/055A1795-HDR.jpg";
import jadeOceanGallery36 from "../assets/images/jade-ocean/jade-ocean_4004/055A1814.jpg";


export const galleryArray = [
  { id: "1", img: jadeOceanGallery01 },
  { id: "2", img: jadeOceanGallery02 },
  { id: "3", img: jadeOceanGallery03 },
  { id: "4", img: jadeOceanGallery04 },
  { id: "5", img: jadeOceanGallery05 },
  { id: "6", img: jadeOceanGallery06 },
  { id: "7", img: jadeOceanGallery07 },
  { id: "8", img: jadeOceanGallery08 },
  { id: "9", img: jadeOceanGallery09 },
  { id: "10", img: jadeOceanGallery10 },
  { id: "11", img: jadeOceanGallery11 },
  { id: "12", img: jadeOceanGallery12 },
  { id: "13", img: jadeOceanGallery13 },
  { id: "14", img: jadeOceanGallery14 },
  { id: "15", img: jadeOceanGallery15 },
  { id: "16", img: jadeOceanGallery16 },
  { id: "17", img: jadeOceanGallery17 },
  { id: "18", img: jadeOceanGallery18 },
  { id: "19", img: jadeOceanGallery19 },
  { id: "20", img: jadeOceanGallery20 },
  { id: "21", img: jadeOceanGallery21 },
  { id: "22", img: jadeOceanGallery22 },
  { id: "23", img: jadeOceanGallery23 },
  { id: "24", img: jadeOceanGallery24 },
  { id: "25", img: jadeOceanGallery25 },
  { id: "26", img: jadeOceanGallery26 },
  { id: "27", img: jadeOceanGallery27 },
  { id: "28", img: jadeOceanGallery28 },
  { id: "29", img: jadeOceanGallery29 },
  { id: "30", img: jadeOceanGallery30 },
  { id: "31", img: jadeOceanGallery31 },
  { id: "32", img: jadeOceanGallery32 },
  { id: "33", img: jadeOceanGallery33 },
  { id: "34", img: jadeOceanGallery34 },
  { id: "35", img: jadeOceanGallery35 },
  { id: "36", img: jadeOceanGallery36 },
];

export const imgCarousel = [
  {
    id: "1",
    img: discover01,
    price: "$36,900,000",
    address: "1451 Brickell Ave #PH56, Miami, FL 33131",
  },
  {
    id: "2",
    img: discover03,
    price: "$36,900,000",
    address: "1451 Brickell Ave #PH56, Miami, FL 33131",
  },
  {
    id: "3",
    img: collins04,
    price: "$15,900,000",
    address: "16901 Collins Avenue, Sunny Isles Beach, FL 33160",
  },
  {
    id: "4",
    img: signature03,
    price: "$3,325,000",
    address: "17121 Collins Ave #2608, Sunny Isles Beach, FL, 33160",
  },
];

export const imgOra = [
  {
    id: "1",
    img: ora01,
    price: "$25,900,000",
    address: "1300 Brickell Ave<br>Miami, FL 33131",
  },
  {
    id: "2",
    img: ora02,
    price: "$25,900,000",
    address: "1300 Brickell Ave<br>Miami, FL 33131",
  },
  {
    id: "3",
    img: ora03,
    price: "$25,900,000",
    address: "1300 Brickell Ave<br>Miami, FL 33131",
  },
  {
    id: "4",
    img: ora04,
    price: "$25,900,000",
    address: "1300 Brickell Ave<br>Miami, FL 33131",
  },
  {
    id: "5",
    img: ora05,
    price: "$25,900,000",
    address: "1300 Brickell Ave<br>Miami, FL 33131",
  },
  {
    id: "6",
    img: ora06,
    price: "$25,900,000",
    address: "1300 Brickell Ave<br>Miami, FL 33131",
  },
  {
    id: "6",
    img: ora07,
    price: "$25,900,000",
    address: "1300 Brickell Ave<br>Miami, FL 33131",
  },
];

export const imgCollins = [
  {
    id: "1",
    img: collins01,
    price: "$15,900,000",
    address: "16901 Collins Avenue, Sunny Isles Beach, FL 33160",
  },
  {
    id: "2",
    img: collins02,
    price: "$15,900,000",
    address: "16901 Collins Avenue, Sunny Isles Beach, FL 33160",
  },
  {
    id: "3",
    img: collins03,
    price: "$15,900,000",
    address: "16901 Collins Avenue, Sunny Isles Beach, FL 33160",
  },
  {
    id: "4",
    img: collins04,
    price: "$15,900,000",
    address: "16901 Collins Avenue, Sunny Isles Beach, FL 33160",
  },
  {
    id: "5",
    img: collins05,
    price: "$15,900,000",
    address: "16901 Collins Avenue, Sunny Isles Beach, FL 33160",
  },
];

export const imgTower = [
  {
    id: "1",
    img: tower01,
    price: "$13,250,000",
    address: "3400 SW 27th Ave, Miami, FL 33133",
  },
  {
    id: "2",
    img: tower02,
    price: "$13,250,000",
    address: "3400 SW 27th Ave, Miami, FL 33133",
  },
  {
    id: "3",
    img: tower03,
    price: "$13,250,000",
    address: "3400 SW 27th Ave, Miami, FL 33133",
  },
  {
    id: "4",
    img: tower04,
    price: "$13,250,000",
    address: "3400 SW 27th Ave, Miami, FL 33133",
  },
  {
    id: "5",
    img: tower05,
    price: "$13,250,000",
    address: "3400 SW 27th Ave, Miami, FL 33133",
  },
];

export const jadeOcean = [
  {
    id: "1",
    img: signature01,
    price: "$3,325,000",
    address: "16901 Collins Avenue #5105Sunny Isles Beach, FL 33160",
  },
  {
    id: "2",
    img: signature02,
    price: "$3,325,000",
    address: "16901 Collins Avenue #5105Sunny Isles Beach, FL 33160",
  },
  {
    id: "3",
    img: signature03,
    price: "$3,325,000",
    address: "16901 Collins Avenue #5105Sunny Isles Beach, FL 33160",
  },
  {
    id: "4",
    img: signature04,
    price: "$3,325,000",
    address: "16901 Collins Avenue #5105Sunny Isles Beach, FL 33160",
  },
  {
    id: "5",
    img: signature05,
    price: "$3,325,000",
    address: "16901 Collins Avenue #5105Sunny Isles Beach, FL 33160",
  },
  {
    id: "6",
    img: signature06,
    price: "$3,325,000",
    address: "16901 Collins Avenue #5105Sunny Isles Beach, FL 33160",
  },
  {
    id: "7",
    img: signature07,
    price: "$3,325,000",
    address: "16901 Collins Avenue #5105Sunny Isles Beach, FL 33160",
  },
  {
    id: "8",
    img: signature08,
    price: "$3,325,000",
    address: "16901 Collins Avenue #5105Sunny Isles Beach, FL 33160",
  },
	{
    id: "9",
    img: signature09,
    price: "$3,325,000",
    address: "16901 Collins Avenue #5105Sunny Isles Beach, FL 33160",
  },
];

export const imgCarouselBanner = [
  {
    id: "1",
    img: Test1,
    // price: "$36,900,000",
    // address: "1451 Brickell Ave #PH56, Miami, FL 33131",
  },
  {
    id: "2",
    img: banner01,
    // price: "$36,900,000",
    // address: "1451 Brickell Ave #PH56, Miami, FL 33131",
  },
  {
    id: "3",
		img: signature09,
    // price: "$36,900,000",
    // address: "1451 Brickell Ave #PH56, Miami, FL 33131",
  },
  {
    id: "4",
    img: tower05,
    // price: "$36,900,000",
    // address: "1451 Brickell Ave #PH56, Miami, FL 33131",
  },
  {
    id: "5",
		img: House,
    // price: "$36,900,000",
    // address: "1451 Brickell Ave #PH56, Miami, FL 33131",
  },
];

export const houseImages = [
  {
    id: "1",
    img: House,
    price: "$36,900,000",
    address: "1451 Brickell Ave #PH56, Miami, FL 33131",
  },
  {
    id: "2",
    img: House,
    price: "$36,900,000",
    address: "1451 Brickell Ave #PH56, Miami, FL 33131",
  },
  {
    id: "3",
    img: House,
    price: "$36,900,000",
    address: "1451 Brickell Ave #PH56, Miami, FL 33131",
  },
  {
    id: "4",
    img: House,
    price: "$36,900,000",
    address: "1451 Brickell Ave #PH56, Miami, FL 33131",
  },
  {
    id: "5",
    img: House,
    price: "$36,900,000",
    address: "1451 Brickell Ave #PH56, Miami, FL 33131",
  },
];
